import React, { useEffect } from "react";
import EventFormContainer from "../components/EventFormContainer";
import "./Tickets.css";
import CommonButton from "../../Common/CommonButton";
import IconButton from "@mui/material/IconButton";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { styled } from "@mui/system";
import { useState } from "react";
import { makeStyles } from "@mui/styles";
import Chip from "@mui/material-next/Chip";
import CustomStep from "../components/CustomStep";
import { useSelector, useDispatch } from "react-redux";
import {
  setTotalPrice,
  setTicketTypesData,
  setTicketTotalsData,
  setFlatDiscount,
  setTotalIncludedInTicketPrice,
} from "../../../reducers/eventDataReducer";
import DisclaimerText from "../components/DisclaimerText";

const CustomButton = styled(IconButton)({
  border: "1px solid #2D72E2",
  margin: "0 10px",
  color: "#2D72E2",
  width: "56px",
  height: "56px",
  borderRadius: "0",
  fontFamily: "Poppins",
});
const CounterValue = styled("div")({
  fontSize: "1.5rem",
  minWidth: "60px",
  textAlign: "center",
  fontFamily: "Poppins",
});
const useStyles = makeStyles((theme) => ({
  selectedChip: {
    "&.MuiChip-outlined": {
      fontFamily: "Poppins",
      borderColor: "#2D72E2",
      backgroundColor: "#2D72E2",
      color: "#FFFFFF ",
      textTransform: "Sentence-case",
      fontFamily: "Poppins",
    },
  },
  deselectedChip: {
    "&.MuiChip-outlined": {
      fontFamily: "Poppins",
      borderColor: "#E9E9E9",
      backgroundColor: "#FFF",
      color: "#2D72E2 ",
      textTransform: "Sentence-case",
      fontFamily: "Poppins",
    },
  },

  soldOutChip: {
    "&.MuiChip-outlined": {
      fontFamily: "Poppins",
      borderColor: "#F04438",
      backgroundColor: "#F04438",
      color: "#FFFFFF",
      textTransform: "Sentence-case",
      fontSize: "0.70rem",
      padding: "2px 0px",
      height: "auto",
      fontFamily: "Poppins",
    },
  },
  chipContainer: {
    display: "flex",
    flexWrap: "wrap",
    gap: "10px",
    fontFamily: "Poppins",
  },
}));

const Tickets = ({ onContinue }) => {
  const [ticketTypeCounters, setTicketTypeCounters] = useState({});
  const [counter, setCounter] = useState(0);
  const [selectedTicket, setSelectedTicket] = useState("all");
  const [isTruncated, setIsTruncated] = useState(true);
  const classes = useStyles();
  const MAX_VISIBLE_TICKETS = 10;
  const MAX_DESCRIPTION_LENGTH = 150;
  const dispatch = useDispatch();
  const flatDiscount = useSelector((state) => state.flatDiscount);
  const eventData = useSelector((state) => state.eventData);

  const eventTicketFlatDiscount = eventData[0]?.eventTrackingCodes;

  console.log("EventData", eventTicketFlatDiscount);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const trackingCode = params.get("utm");
    let determinedDiscount = 0;

    if (trackingCode && eventData[0]?.eventTrackingCodes) {
      const trackingObj = eventData[0].eventTrackingCodes.find(
        (code) => code.code === trackingCode
      );
      if (trackingObj) {
        determinedDiscount = trackingObj.discountAmount;
      }
    }
    dispatch(setFlatDiscount(determinedDiscount));
  }, [eventData, dispatch]);

useEffect(() => {
  const totalIncludedInTicketPrice =
    eventData[0]?.eventTicketTypeCategories.reduce((total, category) => {
      return (
        total +
        category.ticketTypes.reduce((subTotal, ticketType) => {
          const count = ticketTypeCounters[ticketType.id] || 0;
          const includedPrice = ticketType.bookingFee;
          return subTotal + includedPrice * count;
        }, 0)
      );
    }, 0);

  console.log("Total Included In Ticket Price:", totalIncludedInTicketPrice); 

  dispatch(setTotalIncludedInTicketPrice(totalIncludedInTicketPrice));
}, [ticketTypeCounters, eventData, dispatch]);


  const allTicketTypes = eventData[0]?.eventTicketTypeCategories.reduce(
    (acc, category) => [...acc, ...category.ticketTypes],
    []
  );

  const handleChipClick = (ticketType) => {
    setSelectedTicket(ticketType);
    setCounter(0);
  };

  const selectedTicketType = allTicketTypes.find(
    (tt) => tt.id === selectedTicket
  );
  console.log("SelectedTicketType", selectedTicketType);

  const truncateDescription = (description) => {
    if (description.length <= MAX_DESCRIPTION_LENGTH) return description;
    return description.substr(0, MAX_DESCRIPTION_LENGTH) + "...";
  };

  const updateTicketTypeCounter = (ticketTypeId, increment) => {
    setTicketTypeCounters((prevCounters) => {
      const currentCount = prevCounters[ticketTypeId] || 0;
      return {
        ...prevCounters,
        [ticketTypeId]: increment
          ? currentCount + 1
          : Math.max(currentCount - 1, 0),
      };
    });
  };

  const totalPrice = eventData[0]?.eventTicketTypeCategories.reduce(
    (total, category) => {
      return (
        total +
        category.ticketTypes.reduce((subTotal, ticketType) => {
          const count = ticketTypeCounters[ticketType.id] || 0;
          const originalPrice = ticketType.ticketPrice.valueNet;
          return subTotal + originalPrice * count;
        }, 0)
      );
    },
    0
  );

  const ticketTotals = Object.entries(ticketTypeCounters)
    .map(([ticketTypeId, count]) => {
      const ticketType = allTicketTypes.find(
        (tt) => tt.id === parseInt(ticketTypeId)
      );
      return ticketType
        ? {
            ticketTypeId: ticketType.id,
            name: ticketType.name,
            count: count,
            total: ticketType.ticketPrice.value * count,
            currencySymbol: ticketType.ticketPrice.currency.symbol,
          }
        : null;
    })
    .filter((item) => item !== null);

  dispatch(setTicketTotalsData(ticketTotals));

  useEffect(() => {
    dispatch(setTotalPrice(totalPrice));
  }, [totalPrice, dispatch]);

  const toggleTruncation = () => {
    setIsTruncated(!isTruncated);
  };
  const getCurrencySymbol = () => {
    const firstTicketType =
      allTicketTypes.length > 0 ? allTicketTypes[0] : null;
    return firstTicketType ? firstTicketType.ticketPrice.currency.symbol : "";
  };

  // Inside the Tickets component

  
    const renderTicketDetails = (ticketType) => {
    const mainPrice = ticketType.ticketPrice.value;
    const originalPrice = ticketType.ticketPrice.valueNet;
    const currencySymbol = ticketType.ticketPrice.currency.symbol;
    const includedInTicketPrice = ticketType.bookingFee;

    return (
      <div className="content-parent">
        <div className="content25">
          <div className="user-selection-and-pricing">
            <div className="text-and-supporting-text2">
              <div className="text25">{ticketType.name}</div>
              <div className="supporting-text-desc">
                <ul style={{ paddingLeft: 20, margin: 0 }}>
                  {ticketType?.description?.split("\n").map((val, index) => {
                    if (val.trim() !== "") {
                      return (
                        <li key={index} style={{ margin: 4 }}>
                          {val}
                        </li>
                      );
                    }
                    return null;
                  })}
                </ul>
              </div>
              <div className="supporting-text30">
                <div style={{ display: "inline-block" }}>
                  {mainPrice !== originalPrice && (
                    <span
                      style={{
                        textDecoration: "line-through",
                        color: "#767676",
                        marginRight: "5%",
                      }}
                    >
                      {currencySymbol}
                      {mainPrice.toFixed(2)}
                    </span>
                  )}
                  <span>
                    {currencySymbol}
                    {originalPrice.toFixed(2)}
                  </span>
                </div>
                {includedInTicketPrice > 0 && (
                  <div className="supporting-incl-text">
                    {` ( excl. ${currencySymbol}${includedInTicketPrice.toFixed(
                      2
                    )} fee )`}
                  </div>
                )}
              </div>
            </div>
            <div
              className="plusMinus-btn"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <CustomButton
                onClick={() => updateTicketTypeCounter(ticketType.id, false)}
                disabled={ticketTypeCounters[ticketType.id] <= 0}
                style={{
                  width: "40px",
                  height: "40px",
                  padding: "5px",
                }}
              >
                <RemoveIcon style={{ color: "white" }} />
              </CustomButton>
              <CounterValue>
                {ticketTypeCounters[ticketType.id] || 0}
              </CounterValue>
              <CustomButton
                onClick={() => updateTicketTypeCounter(ticketType.id, true)}
                style={{
                  width: "40px",
                  height: "40px",
                  padding: "5px",
                }}
              >
                <AddIcon style={{ color: "white" }} />
              </CustomButton>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderSelectedTicketTypes = () => {
    return Object.entries(ticketTypeCounters).map(([ticketTypeId, count]) => {
      if (count > 0) {
        const ticketType = allTicketTypes.find(
          (tt) => tt.id === parseInt(ticketTypeId)
        );
        return (
          <>
            <div className="supporting-text29">
              {count} x {ticketType ? ticketType.name : "[ticket type]"}
            </div>
          </>
        );
      }
      return null;
    });
  };

  const renderSelectedTicketTotal = () => {
    return Object.entries(ticketTypeCounters).map(([ticketTypeId, count]) => {
      if (count > 0) {
        const ticketType = allTicketTypes.find(
          (tt) => tt.id === parseInt(ticketTypeId)
        );
        if (!ticketType) return null;
        const originalPrice = ticketType.ticketPrice.valueNet;
        const currencySymbol = ticketType.ticketPrice.currency.symbol;

        return (
          <div key={ticketTypeId} className="supporting-text29">
            <span>
              {currencySymbol}
              {originalPrice.toFixed(2)}
            </span>
          </div>
        );
      }
      return null;
    });
  };

  useEffect(() => {
    dispatch(setTotalPrice(totalPrice));
  }, [ticketTypeCounters, flatDiscount, eventData, dispatch]);

  const renderCategoryWithTickets = (category) => (
    <div key={category.id} className="content-parent">
      <div className="text26 popupTitle-text">{category.name}</div>
      <div className="supporting-text29">
        {truncateDescription(
          category?.description || "No description available"
        )}
      </div>
      {category.ticketTypes.map((ticketType) =>
        renderTicketDetails(ticketType)
      )}
    </div>
  );

  const renderAllTickets = () => {
    return eventData[0]?.eventTicketTypeCategories.map((category) =>
      renderCategoryWithTickets(category)
    );
  };

  return (
    <div className="desktop3">
      <div className="modal2">
        <EventFormContainer whichmodule={"ticket"} />
        <div className={classes.chipContainer}>
          <div className={classes.chipContainer}>
            <Chip
              label="All tickets"
              variant="outlined"
              className={
                selectedTicket === "all"
                  ? classes.selectedChip
                  : classes.deselectedChip
              }
              onClick={() => handleChipClick("all")}
            />

            {eventData[0]?.eventTicketTypeCategories.map((category) => (
              <Chip
                key={category.id}
                label={category.name}
                variant="outlined"
                className={
                  selectedTicket === category.id
                    ? classes.selectedChip
                    : classes.deselectedChip
                }
                onClick={() => handleChipClick(category.id)}
              />
            ))}

            {eventData[0]?.eventTicketTypeCategories.length >
              MAX_VISIBLE_TICKETS && (
              <button onClick={toggleTruncation}>
                {isTruncated ? "Show More" : "Show Less"}
              </button>
            )}
          </div>

          {selectedTicket === "all"
            ? renderAllTickets()
            : renderCategoryWithTickets(
                eventData[0]?.eventTicketTypeCategories.find(
                  (category) => category.id === selectedTicket
                )
              )}
        </div>
        <div className="content-parent price-total-modalTick">
          <div className="content25">
            <div className="user-selection-and-pricing">
              <div className="text-and-supporting-text3">
                {renderSelectedTicketTypes()}
                <div className="supporting-text30">Total</div>
              </div>
              <div style={{ display: "flex" }}>
                <div className="text-and-supporting-text3">
                  {renderSelectedTicketTotal()}
                  <div className="supporting-text30">
                    {getCurrencySymbol()}
                    {totalPrice.toFixed(2)}
                  </div>
                </div>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                width: "328px",
                height: "48px",
                minWidth: "296px",
                maxWidth: "360px",
                alignSelf: "center",
              }}
            >
              <CommonButton
                text="Continue"
                onClick={() => {
                  if (totalPrice > 0) onContinue();
                }}
                width="100%"
                height="52px"
                fontSize="28px"
                textAlign="center"
                backgroundColor="#2D72E2"
                borderColor="#2D72E2"
                disabled={totalPrice <= 0}
              />
            </div>
          </div>
        </div>

        <div className="content15">
          <DisclaimerText />
        </div>
      </div>
    </div>
  );
};

export default Tickets;
