import CommonButton from "../../Common/CommonButton";
import "./SuccessPage.css";
import { useEffect, useState } from "react";
import cookie from "react-cookies";
import { updateOrder } from "../../../services/api";
import { CircularProgress } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { setEventData } from "../../../reducers/eventDataReducer";
const stripe = require("stripe")(
  "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"
);

const SuccessPage = () => {
  const [loader, setLoader] = useState(true);
  const [updatePerformed, setUpdatePerformed] = useState(false);

  const handleContinueClick = () => {
    window.location.href = "https://moonparty.twotixx.com/app";
  };

  const dispatch = useDispatch();
  const eventData = useSelector((state) => state.eventData);

  useEffect(() => {
    const sessionId = cookie.load("stripeSessionId");
    if (sessionId && !updatePerformed) {
        stripe.checkout.sessions
            .retrieve(sessionId)
            .then(async (session) => {
                const orderId = parseInt(session?.metadata?.orderId);
                const paymentIntent = session?.payment_intent;
                if (orderId && session?.status === "complete") {
                    const params = {
                        orderId: orderId,
                        paymentStatus: "Approved",
                        paymentRef: paymentIntent,
                        StripeIntentId: paymentIntent || '',
                    };
                    setLoader(false);
                    await handleUpdateOrder(params);
                } else {
                    const params = {
                        orderId: orderId,
                        paymentStatus: "Failed",
                        paymentRef: paymentIntent,
                    };
                    setLoader(false);
                    await handleUpdateOrder(params);
                }
              setUpdatePerformed(true);
            })
            .catch((e) => {
                cookie.remove("stripeSessionId");
                setLoader(false);
            });
    }
}, [updatePerformed]);

  const handleUpdateOrder = async (params) => {
    await updateOrder(params);
    cookie.remove("stripeSessionId");
    setLoader(false);
  };

  useEffect(() => {
    if (!eventData || eventData.length === 0) {
      const storedEventData = sessionStorage.getItem("eventData");
      if (storedEventData) {
        const parsedEventData = JSON.parse(storedEventData);
        dispatch(setEventData(parsedEventData));
        console.log("Event Data from sessionStorage:", parsedEventData);
      }
    } else {
      console.log("Event Data from Redux store:", eventData);
    }
  }, [dispatch, eventData]);

  const eventName = eventData[0]?.name || "";

  return (
    <div className="forsussesspage">
      {loader ? (
        <CircularProgress />
      ) : (
        <>
          {/* <div className="header5">
          <img
            className="check-verified-02-icon"
            alt=""
            src="/checkverified02.svg"
          />
          <div className="text-and-supporting-text">
            <div className="text22">PAYMENT CONFIRMED</div>
            <div className="supporting-text25">
              Your ticket(s) will be will waiting for you in the Twotixx App.
            </div>
          </div>
        </div>
        <div className="text23">DOWNLOAD APP</div>
        <div className="rectangle-parent">
          <img className="group-child" alt="" src="/adobe-express-qr-code.png" />
          <div className="group-item" />
        </div>
        <CommonButton
          text="Download Now"
          onClick={handleContinueClick}
          width="100%"
          height="52px"
          fontSize="28px"
          fontFamily="Poppins"
        />
      </div>} */}

          <div className="header5">
            <img
              className="check-verified-02-icon"
              alt=""
              src="/SuccessVector.svg"
            />
            <div className="text-and-supporting-text">
              <div className="success-header">Booking complete</div>

              <div className="supporting-text25 mb-20">
                Congratulations, you're going to{" "}
                <span className="eventName">{eventName}</span>!
              </div>
              <div className="supporting-text25">
                An order confirmation and a verification email have been sent to
                you. Please click the link to verify your account.
              </div>
            </div>
          </div>
          <div className="successtext">
            Access your tickets in the Twotixx app
          </div>
          {/* <div className="supporting-text25">
            Create an account with the same email address <br /> used for your
            purchase. Download the app below.
          </div> */}
          <div className="rectangle-parent">
            <img className="group-child" alt="" src="/success_page_qr.png" />
            <div className="group-item" />
          </div>
          <CommonButton
            onClick={handleContinueClick}
            height="52px"
            fontSize="28px"
            fontFamily="Poppins"
            text="Download on iOS"
            backgroundColor="#2D72E2"
            borderColor="#2D72E2"
            fontColor="#FFFFFF"
            icon="/apple.svg"
          />
          <CommonButton
            text="Download on Android"
            onClick={handleContinueClick}
            height="52px"
            fontSize="28px"
            fontFamily="Poppins"
            backgroundColor="#2D72E2"
            borderColor="#2D72E2"
            fontColor="#FFFFFF"
            icon="/android.svg"
          />
        </>
      )}
    </div>
  );
};

export default SuccessPage;
