const stripe = require("stripe")(
  "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"
);
const noRefundsPolicy = true; 
const CreateStripeSession = async (formData) => {
  const redirectURL =
    process.env.NODE_ENV === "development"
      ? "http://localhost:3000"
      : "https://moonparty.twotixx.com";

  let description = `Get ready to elevate your spirits and soar with our electrifying 3rd edition Moon Party in Las Vegas! This isn't just any party; it's a crypto music revolution under the stars. Featuring live performances from the trailblazing XRP Bags, the enchanting Lady E, the dynamic Lemi, and the soul-stirring Krown Chakra, this concert promises to be an unforgettable fusion of cryptocurrency culture and mesmerizing music!`;
  if (noRefundsPolicy) {
    description += " This event has a no refund policy.";
  }
  console.log("No refunds policy:", noRefundsPolicy);

  try {
    const session = await stripe.checkout.sessions.create({
      payment_method_types: ["card"],
      line_items: [
        {
          price_data: {
            currency: formData.currency || "USD",
            unit_amount: Math.round(parseFloat(formData.amount) * 100),
            product_data: {
              name: "Moon Party- Live Show",
              description: description,
            },
          },
          quantity: 1,
        },
      ],
      mode: "payment",
      billing_address_collection: "required",
      customer_email: formData?.email,
      success_url: redirectURL + "/success",
      cancel_url: redirectURL + "/cancel",
      metadata: {
        orderId: formData?.orderId,
        email: formData?.email,
      },
    });
    return { id: session.id, orderId: formData.orderId };
  } catch (error) {
    console.error("Error creating Stripe session:", error);
    throw error;
  }
};

export default CreateStripeSession;
