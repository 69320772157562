import EventFormContainer from "../components/EventFormContainer";
import "./EventCustomerDetailsStepper.css";
import CommonButton from "../../Common/CommonButton";
import { TextField, Grid, Tooltip, InputAdornment } from "@mui/material";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import InfoIcon from "@mui/icons-material/Info";
import IconButton from "@mui/material/IconButton";
import { makeStyles } from "@mui/styles";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";

import {
  upperCaseReg,
  lowerCaseReg,
  numberReg,
  symbolReg,
} from "../../../constants/regex";

import { ReactComponent as ShowIcon } from "../../../assets/svgs/icon-show.svg";
import { ReactComponent as HideIcon } from "../../../assets/svgs/icon-hide.svg";

import cookie from "react-cookies";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import {
  createOrder,
  emailInUse,
  signUp,
  updateOrder,
} from "../../../services/api";

import { useSelector, useDispatch } from "react-redux";
import {
  setExistingEmail,
  setRegistrationEmail,
  setNewRegistration,
} from "../../../reducers/eventDataReducer";
import { loadStripe } from "@stripe/stripe-js";
import CreateStripeSession from "../../../utils/StripeSession";
import DisclaimerText from "../components/DisclaimerText";
import usePasswordValidation from "../../../hooks/usePasswordValidation";

const useStyles = makeStyles({
  root: {
    "& .MuiFormControl-root": {
      width: "100%",
      margin: "10px 0",
    },
    "& .MuiInputLabel-root": {
      textTransform: "uppercase",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "rgba(0, 0, 0, 0.23)",
      },
      "&:hover fieldset": {
        borderColor: "black",
      },
      "&.Mui-focused fieldset": {
        borderColor: "black",
      },
    },
    "& .MuiInputBase-input": {
      fontSize: "16px",
      fontFamily: "Poppins",
      color: "black",
    },
  },
  tooltip: {
    fontSize: "12px",
    maxWidth: "180px",
    fontFamily: "Poppins",
    backgroundColor: "#FFFFFF",
    boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.2)",
    "@media (max-width: 600px)": {
      fontSize: "10px",
      maxWidth: "150px",
    },
  },
  tooltipContent: {
    backgroundColor: "transparent",
    color: "#0D2244",
    fontSize: "14px",
    fontFamily: "Poppins",
  },
  bookingFeeText: {
    marginTop: "8px",
    fontFamily: "Poppins",
  },
  dobField: {
    "& .MuiGrid-item": {
      paddingLeft: 30,
      paddingRight: 0,
    },
  },
  iconButton: {
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
});

const CustomerDetailsStepper = ({ onContinue }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [openTotalPriceInfo, setOpenTotalPriceInfo] = useState(false);
  const [isTouchDevice, setIsTouchDevice] = useState(false);
  const tooltipTriggerRef = useRef(null);
  const newTooltipTriggerRef = useRef(null);
  const [initialCheckDone, setInitialCheckDone] = useState(false);
  const [errors, setErrors] = useState({});
  const [redirectToPayment, setRedirectToPayment] = useState(false);
  const [showAdditionalFields, setShowAdditionalFields] = useState(false);
  const totalPrice = useSelector((state) => state.totalPrice);
  const eventData = useSelector((state) => state.eventData);
  const trackingCode = useSelector((state) => state.trackingCode);
  const existingEmail = useSelector((state) => state.existingEmail);
  const isNewRegistration = useSelector((state) => state.isNewRegistration);
  const ticketTotalsData = useSelector((state) => state.ticketTotalsData);
  const flatDiscount = useSelector((state) => state.flatDiscount);
  const totalIncludedInTicketPrice = useSelector(
    (state) => state.totalIncludedInTicketPrice
  );
  const [showDateError, setShowDateError] = useState(false);

  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [showPassword, setShowPassword] = useState(false);
  const [confirmShowPassword, setConfirmShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isPasswordFocused, setIsPasswordFocused] = useState(false);
  const monthRef = useRef(null);
  const yearRef = useRef(null);
  const finalTotalPrice = useMemo(() => {
    if (totalIncludedInTicketPrice > 0) {
      return totalPrice + totalIncludedInTicketPrice;
    }
    return totalPrice;
  }, [totalPrice, totalIncludedInTicketPrice]);

  const [formData, setFormData] = useState({
    email: "",
    password: "",
    confirmPassword: "",
    lastName: "",
    firstName: "",
    day: "",
    month: "",
    year: "",
    currency: "USD",
    amount: finalTotalPrice,
    // orderId:
  });

  const { isValid, unmetCriteria } = usePasswordValidation(formData.password);
  const passwordCriteria = ["Length", "Upper", "Lower", "Number", "Symbol"];
  const handleChange = (fieldName) => (event) => {
    let value = event.target.value;
    setFormData({ ...formData, [fieldName]: value });
  };

  const handleDateChange = (fieldName) => (event) => {
    let value = event.target.value;
    if (/^\d*$/.test(value)) {
      setFormData({ ...formData, [fieldName]: value });
      if (fieldName === "day" && value.length === 2) {
        monthRef.current.focus();
      } else if (fieldName === "month" && value.length === 2) {
        yearRef.current.focus();
      }
    }
  };
  useEffect(() => {
    function updateIsTouchDevice() {
      setIsTouchDevice(true);
    }
    window.addEventListener("touchstart", updateIsTouchDevice, { once: true });
    return () => {
      window.removeEventListener("touchstart", updateIsTouchDevice);
    };
  }, []);

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        tooltipTriggerRef.current &&
        !tooltipTriggerRef.current.contains(event.target)
      ) {
        setOpen(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [tooltipTriggerRef]);

  const toggleShowPassword = () => {
    setShowPassword((prevState) => !prevState);
  };
  const toggleConfirmShowPassword = () => {
    setConfirmShowPassword((prevState) => !prevState);
  };
  const handleTooltipToggle = (e) => {
    if (isTouchDevice) {
      e.preventDefault();
    }
    setOpen(!open);
  };

  const handleMouseEnter = () => {
    if (!isTouchDevice) {
      setOpen(true);
    }
  };

  const handleMouseLeave = () => {
    if (!isTouchDevice) {
      setOpen(false);
    }
  };

  const handleTotalPriceInfoToggle = () => {
    setOpenTotalPriceInfo((prevOpen) => !prevOpen);
  };
  const handleNewTooltipMouseEnter = () => {
    if (!isTouchDevice) {
      setOpenTotalPriceInfo(true);
    }
  };

  const handleNewTooltipMouseLeave = () => {
    if (!isTouchDevice) {
      setOpenTotalPriceInfo(false);
    }
  };

  const handleNewTooltipClick = () => {
    setOpenTotalPriceInfo((prevOpen) => !prevOpen);
  };

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        tooltipTriggerRef.current &&
        !tooltipTriggerRef.current.contains(event.target)
      ) {
        setOpenTotalPriceInfo(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  useEffect(() => {
    let timer;
    if (showError) {
      timer = setTimeout(() => {
        setShowError(false);
      }, 2000);
    }
    return () => clearTimeout(timer);
  }, [showError]);
  const allTicketTypes = eventData[0]?.eventTicketTypeCategories.reduce(
    (acc, category) => [...acc, ...category.ticketTypes],
    []
  );
  const exclData = eventData;
  const getCurrencySymbol = () => {
    const firstTicketType =
      allTicketTypes.length > 0 ? allTicketTypes[0] : null;
    return firstTicketType ? firstTicketType.ticketPrice.currency.symbol : "";
  };

  const renderTicketDetails = () => {
    return ticketTotalsData
      ?.filter((ticketType) => ticketType.count > 0)
      .map((ticketType) => {
        const discountedTotal = Math.max(
          ticketType.total - flatDiscount * ticketType.count,
          0
        );

        return (
          <div key={ticketType.ticketTypeId} className="row2">
            <div className="text10">
              {ticketType.count} x {ticketType.name}
            </div>
            <div
              className="supporting-text20"
              style={{ color: "var(--brand-primary-brand)" }}
            >
              {ticketType.currencySymbol}
              {discountedTotal.toFixed(2)}
            </div>
          </div>
        );
      });
  };

  const validateEmail = (email) =>
    /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email);

  const checkEmail = async () => {
    if (!formData.email) {
      setErrors({ ...errors, email: "Email is required." });
      setShowAdditionalFields(false);
      return;
    }
    if (!validateEmail(formData.email)) {
      setErrors({ ...errors, email: "Email is not valid." });
      setShowAdditionalFields(false);
      return;
    }

    try {
      const response = await emailInUse({ EmailAddress: formData.email });
      if (response.status && response.response === true) {
        dispatch(setExistingEmail(formData.email));
        const { orderId } = await submitOrder(formData.email);
        createCheckOutSession(orderId, formData.email);
      } else if (response.status && response.response === false) {
        setErrors({ ...errors, email: "" });
        setShowAdditionalFields(true);
        setInitialCheckDone(true);
      }
    } catch (error) {
      console.error("Error checking email:", error);
    }
  };

  const validate = () => {
    let tempErrors = {};
    tempErrors.email = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(
      formData.email
    )
      ? ""
      : "Email is not valid.";

    tempErrors.password = isValid
      ? ""
      : "Password must be at least 8 characters long and include uppercase, lowercase, numbers, and special characters.";
    tempErrors.confirmPassword =
      formData.confirmPassword === formData.password
        ? ""
        : "Passwords do not match.";

    const isLeapYear = (year) =>
      year % 4 === 0 && (year % 100 !== 0 || year % 400 === 0);
    const getLastDayOfMonth = (year, month) => {
      if (month === 2) {
        // February
        return isLeapYear(year) ? 29 : 28;
      } else if ([4, 6, 9, 11].includes(month)) {
        return 30;
      } else {
        return 31;
      }
    };

    const day = parseInt(formData.day, 10);
    const month = parseInt(formData.month, 10);
    const year = parseInt(formData.year, 10);

    const lastDay = getLastDayOfMonth(year, month);
    tempErrors.day = day > 0 && day <= lastDay ? "" : "Invalid day.";

    tempErrors.month = month > 0 && month <= 12 ? "" : "Invalid month.";

    tempErrors.year =
      year > 1900 && year <= new Date().getFullYear() ? "" : "Invalid year.";

    setErrors(tempErrors);
    return Object.values(tempErrors).every((x) => x === "");
  };

  useEffect(() => {
    let timer;
    if (showDateError) {
      timer = setTimeout(() => {
        setShowDateError(false);
      }, 2000);
    }

    return () => clearTimeout(timer);
  }, [showDateError]);

  const handleSubmit = async () => {
    if (validate()) {
      try {
        const signUpResponse = await signUp({
          emailAddress: formData.email,
          password: formData.confirmPassword,
          firstName: formData.firstName,
          lastName: formData.lastName,
        });

        if (signUpResponse.status) {
          dispatch(setRegistrationEmail(formData.email));
          dispatch(setNewRegistration(true));
          const { orderId } = await submitOrder(formData.email);
          createCheckOutSession(orderId, formData.email);
        } else {
          const message =
            signUpResponse.message || "Something went wrong. Please try again.";
          setErrorMessage(message);
          setShowError(true);
        }
      } catch (error) {
        let errorMessage = "An unexpected error occurred. Please try again.";
        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          errorMessage = error.response.data.message;
        } else if (error.message) {
          errorMessage = error.message;
        }
        setErrorMessage(errorMessage);
        setShowError(true);
      }
    }
  };

  useEffect(() => {
    if (initialCheckDone) {
      const timer = setTimeout(() => {
        checkEmail();
      }, 500);
      return () => clearTimeout(timer);
    }
  }, [formData.email, initialCheckDone]);

  const handleContinueClick = async () => {
    if (!initialCheckDone) {
      await checkEmail();
    }
    if (redirectToPayment) {
      createCheckOutSession();
    } else {
      if (showAdditionalFields) {
        handleSubmit();
      }
    }
  };

  const submitOrder = async (email) => {
    console.log("Preparing to submit order");
    const orderParams = {
      emailAddress: email || isNewRegistration,
      eventId: eventData[0]?.id,
      trackingCode: trackingCode,
      currencyId: allTicketTypes[0]?.ticketPrice.currency.id,
      orderTotal: finalTotalPrice,
      orderItems: ticketTotalsData.map((ticketType) => ({
        eventTicketTypeId: ticketType.ticketTypeId,
        quantity: ticketType.count,
        bookingFee: ticketType.bookingFee,
        price: ticketType.price,
        total: finalTotalPrice,
      })),
    };
    console.log("OrderParams", orderParams);

    try {
      const orderResponse = await createOrder(orderParams);
      console.log("OrderResponse", orderResponse.response.orderId);
      if (orderResponse && orderResponse.status) {
        console.log(
          "Order created successfully:",
          orderResponse.response.orderId
        );
        setFormData({ ...formData, orderId: orderResponse.response.orderId });
        return { status: true, orderId: orderResponse.response.orderId };
      } else {
        return { status: false, message: "Order creation failed" };
      }
    } catch (error) {
      console.error("Order creation error:", error);
      return { status: false, message: "Error in order creation" };
    }
  };

  const stripePromise = loadStripe(
    "pk_live_51OudyCGngyHTr4IfDy3LrrO6m1SdccMJwwTrBEu5xRUfrH83yBcPhtJo3r2GejpMmO1REI39cn48sYCEcPRspDAw00K31gPe8B"
  );

  const createCheckOutSession = async (orderId, userEmail) => {
    console.log("Creating Stripe session for order ID:", orderId);
    setLoading(true);
    try {
      const sessionResponse = await CreateStripeSession({
        ...formData,
        orderId: orderId || formData.orderId,
        email: userEmail,
      });

      cookie.save("stripeSessionId", sessionResponse.id);
      const params = {
        orderId: orderId,
        paymentStatus: "Pending",
        paymentRef: sessionResponse.id,
        StripeIntentId: sessionResponse.id,
      };
      const { status, message } = await updateOrder(params);

      if (status) {
        const stripe = await stripePromise;
        const result = await stripe.redirectToCheckout({
          sessionId: sessionResponse.id,
        });

        if (result.error) {
          alert("FAILED! " + result.error.message);
        }
      } else {
        alert("FAILED! " + (message || "An unknown error occurred"));
      }
    } catch (error) {
      console.error("Error during checkout:", error);
      let errorMessage = "Please refresh the page and try again.";
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        errorMessage = error.response.data.message;
      } else if (error.message) {
        errorMessage = error.message;
      } else if (typeof error === "string") {
        errorMessage = error;
      }
      if (window.confirm(`ERROR!! ${errorMessage}`)) {
        window.location.reload();
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="desktop2">
      <div className="modal-group">
        <div className="modal1">
          <EventFormContainer whichmodule={"payment"} />
          <img className="divider-icon1" alt="" src="/divider1.svg" />
          <div className="rows1">
            {renderTicketDetails()}
            <div className="row3" style={{ marginTop: 15 }}>
              <div className="text11">Total</div>
              <div
                className="supporting-text21"
                style={{ color: "var(--brand-primary-brand)" }}
              >
                {getCurrencySymbol()}

                {finalTotalPrice.toFixed(2)}

                <span style={{ color: "#767676", fontSize: 14 }}>
                  {` ( incl. ${getCurrencySymbol()}${totalIncludedInTicketPrice.toFixed(
                    2
                  )} fee )`}

                  <Tooltip
                    title={
                      <div className={classes.tooltipContent}>
                        The total price includes fees:
                        <div className={classes.bookingFeeText}>
                          Booking fee: {getCurrencySymbol()}
                          {totalIncludedInTicketPrice.toFixed(2)}
                        </div>
                      </div>
                    }
                    arrow
                    componentsProps={{
                      tooltip: {
                        sx: {
                          bgcolor: "common.white",
                          "& .MuiTooltip-arrow": {
                            color: "common.white",
                          },
                        },
                      },
                    }}
                    classes={{ tooltip: classes.tooltip }}
                    open={openTotalPriceInfo}
                    disableFocusListener={isTouchDevice}
                    disableHoverListener={isTouchDevice}
                    disableTouchListener={isTouchDevice}
                    onMouseEnter={handleNewTooltipMouseEnter}
                    onMouseLeave={handleNewTooltipMouseLeave}
                    placement="right"
                  >
                    <IconButton
                      ref={tooltipTriggerRef}
                      onClick={handleNewTooltipClick}
                      className={classes.iconButton}
                      style={{
                        padding: "10px",
                        width: "48px",
                        height: "48px",
                        marginTop: "-5px",
                      }}
                    >
                      <InfoIcon
                        style={{ color: "#2D72E2", fontSize: "24px" }}
                      />
                    </IconButton>
                  </Tooltip>
                </span>
              </div>
            </div>
          </div>
          <div className="content10">
            <DisclaimerText />
          </div>
        </div>
        <div className="content11">
          <div className="text12">ENTER YOUR DETAILS</div>
          <form className={classes.root} noValidate autoComplete="off">
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <span className={classes.label}>
                  Email address
                  <Tooltip
                    title="Use registered email if you have an existing account."
                    arrow
                    classes={{ tooltip: classes.tooltip }}
                    open={open}
                    disableFocusListener={isTouchDevice}
                    disableHoverListener={isTouchDevice}
                    disableTouchListener={isTouchDevice}
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                    placement="right"
                  >
                    <IconButton
                      ref={tooltipTriggerRef}
                      onClick={handleTooltipToggle}
                      className={classes.iconButton}
                      style={{ padding: "10px" }}
                    >
                      <InfoIcon
                        style={{ color: "#2D72E2", fontSize: "17px" }}
                      />
                    </IconButton>
                  </Tooltip>
                </span>
                <TextField
                  error={!!errors.email}
                  helperText={errors.email}
                  placeholder="Email"
                  variant="outlined"
                  fullWidth
                  value={formData.email}
                  onChange={handleChange("email")}
                />
              </Grid>
              {showAdditionalFields && (
                <>
                  <Grid item xs={12}>
                    <span className={classes.label}>First Name</span>
                    <TextField
                      placeholder="First Name"
                      variant="outlined"
                      fullWidth
                      value={formData.firstName}
                      onChange={handleChange("firstName")}
                    />
                  </Grid>{" "}
                  <Grid item xs={12}>
                    <span className={classes.label}>Last Name</span>
                    <TextField
                      placeholder="Last Name"
                      variant="outlined"
                      fullWidth
                      value={formData.lastName}
                      onChange={handleChange("lastName")}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <span className={classes.label}>Password</span>
                    <TextField
                      error={!!errors.password}
                      helperText={!isPasswordFocused && errors.password}
                      placeholder="Password"
                      variant="outlined"
                      fullWidth
                      type={showPassword ? "text" : "password"}
                      value={formData.password}
                      onChange={handleChange("password")}
                      onFocus={() => setIsPasswordFocused(true)}
                      onBlur={() => setIsPasswordFocused(false)}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton onClick={toggleShowPassword} edge="end">
                              {showPassword ? <HideIcon /> : <ShowIcon />}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  {isPasswordFocused && (
                    <Grid
                      item
                      xs={12}
                      style={{
                        display: "flex",
                        flexWrap: "wrap",
                        alignItems: "center",
                      }}
                    >
                      {isPasswordFocused && (
                        <Grid
                          className="responsive-criteria"
                          item
                          xs={12}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            overflow: "auto",
                            whiteSpace: "nowrap",
                            flexDirection: "row",
                          }}
                        >
                          {passwordCriteria.map((item, index) => (
                            <span
                              key={index}
                              className="responsive-criteria-item"
                              style={{
                                fontSize: "small",
                                marginRight:
                                  index !== passwordCriteria.length - 1
                                    ? "8px"
                                    : "0",
                              }}
                            >
                              {item}:{" "}
                              <span
                                style={{
                                  color: unmetCriteria.includes(item)
                                    ? "red"
                                    : "green",
                                }}
                              >
                                {unmetCriteria.includes(item) ? "No" : "Yes"}
                              </span>
                              {index !== passwordCriteria.length - 1 ? "," : ""}
                            </span>
                          ))}
                        </Grid>
                      )}
                    </Grid>
                  )}
                  <Grid item xs={12}>
                    <span className={classes.label}>Confirm Password</span>
                    <TextField
                      error={!!errors.confirmPassword}
                      helperText={errors.confirmPassword}
                      placeholder="Confirm Password"
                      variant="outlined"
                      fullWidth
                      type={confirmShowPassword ? "text" : "password"}
                      value={formData.confirmPassword}
                      onChange={handleChange("confirmPassword")}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={toggleConfirmShowPassword}
                              edge="end"
                            >
                              {confirmShowPassword ? (
                                <HideIcon />
                              ) : (
                                <ShowIcon />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <span>Date of birth</span>
                  </Grid>
                  <Grid container spacing={2} sx={{ marginLeft: "2px" }}>
                    <Grid item xs={12} sm={4}>
                      <TextField
                        type="text"
                        error={!!errors.day}
                        helperText={errors.day}
                        placeholder="DD"
                        variant="outlined"
                        fullWidth
                        value={formData.day}
                        onChange={handleDateChange("day")}
                        inputProps={{ maxLength: 2 }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <TextField
                        type="text"
                        inputRef={monthRef}
                        error={!!errors.month}
                        helperText={errors.month}
                        placeholder="MM"
                        variant="outlined"
                        fullWidth
                        value={formData.month}
                        onChange={handleDateChange("month")}
                        inputProps={{ maxLength: 2 }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <TextField
                        type="text"
                        inputRef={yearRef}
                        error={!!errors.year}
                        helperText={errors.year}
                        placeholder="YYYY"
                        variant="outlined"
                        fullWidth
                        value={formData.year}
                        onChange={handleDateChange("year")}
                        inputProps={{ maxLength: 4 }}
                      />
                    </Grid>
                  </Grid>
                </>
              )}
            </Grid>
          </form>
          <div
            style={{
              display: "flex",
              width: "328px",
              height: "48px",
              minWidth: "296px",
              maxWidth: "360px",
              alignSelf: "center",
            }}
          >
            <CommonButton
              text="Continue"
              onMouseDown={handleContinueClick}
              width="100%"
              height="46px"
              fontSize="28px"
              textAlign="center"
              backgroundColor="#2D72E2"
              borderColor="#2D72E2"
              disabled={totalPrice <= 0}
            />
          </div>
          {showError && (
            <Stack sx={{ width: "100%" }} spacing={2}>
              <Alert severity="error">{errorMessage}</Alert>
            </Stack>
          )}
        </div>
        <div className="content-new">
          <div className="supporting-text22">
            <DisclaimerText />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomerDetailsStepper;
