import { useState, useMemo } from "react";
import "./Navbar.css";
import CommonButton from "../Common/CommonButton";
import { Link } from "react-router-dom";

const Navbar = (
  {
    // navDBackgroundColor,
    // navDPosition,
    // navDBorderBottom,
    // navDBoxSizing,
    // navDZIndex,
  }
) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  // const navDStyle = useMemo(
  //   () => ({
  //     backgroundColor: navDBackgroundColor,
  //     position: navDPosition,
  //     borderBottom: navDBorderBottom,
  //     boxSizing: navDBoxSizing,
  //     zIndex: navDZIndex,
  //   }),
  //   [
  //     navDBackgroundColor,
  //     navDPosition,
  //     navDBorderBottom,
  //     navDBoxSizing,
  //     navDZIndex,
  //   ]
  // );

  return (
    // <div className="nav-d" style={navDStyle}>
    <div className="nav-d">
      <div className="header">
        <div className="container4">
          <div className="content6">
            <div className="navBarLogo">
              {/* <a href="https://moonparty.twotixx.com">
            <img className="iconnavBarLogo" alt="" src="/icon1.svg" />
          </a> */}
              <Link to="/">
                <img className="iconnavBarLogo" alt="" src="/icon1.svg" />
              </Link>
            </div>
            {/* <div className="hamburger-icon" onClick={toggleMenu}>
              <img alt="Menu" src="/menu.svg" />
            </div> */}
          </div>

          {isMenuOpen || (
            <div className="navigation-parent">
              {/* <div className="navigation">
                <div className="button3">
                  <img
                    className="placeholder-icon13"
                    alt=""
                    src={placeholder}
                  />
                  <div className="text8">Browse Events</div>
                  <img
                    className="placeholder-icon13"
                    alt=""
                    src={placeholder1}
                  />
                </div>
                <div className="button3">
                  <img
                    className="placeholder-icon13"
                    alt=""
                    src={placeholder2}
                  />
                  <div className="text8">About</div>
                  <img
                    className="placeholder-icon13"
                    alt=""
                    src={placeholder3}
                  />
                </div>
                <div className="button3">
                  <img
                    className="placeholder-icon13"
                    alt=""
                    src={placeholder4}
                  />
                  <div className="text8">Help</div>
                  <img
                    className="placeholder-icon13"
                    alt=""
                    src={placeholder5}
                  />
                </div>
              </div> */}
              <CommonButton
                text="Download Twotixx"
                onClick={() => window.open("https://moonparty.twotixx.com/app")}
                backgroundColor="#2D72E2"
                borderColor="#2D72E2"
                fontColor="#FFFFFF"
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Navbar;
